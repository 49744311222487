import React, { useEffect } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PaymentForm from './PaymentForm'

const Diego = 'pk_test_51NJ0hELJsUTWMJlYFPcEXiY8E43Kfrj5ecnpYpKIACSLxPCqsdPhYPaaT0knoPmt4wFQERjyolMHJIPrkvnAH1VI00VHrT8oeq';
const David = process.env.REACT_APP_DAVID_API_KEY
const PJInvestments = process.env.REACT_APP_PJINVESTMENTS_API_KEY
const Phill = process.env.REACT_APP_PHILL_API_KEY;
const RCHomes = process.env.REACT_APP_RCHOMES_API_KEY;
const RockCity = process.env.REACT_APP_ROCKCITY_API_KEY;

function StripeContainer(props) {
  let stripeTestPromise = loadStripe();

  console.log(props.propertyName);
  switch (props.propertyName) {
    case "Tuneberg":
      stripeTestPromise = loadStripe(Diego);
      break;
      case " Oak Park Game Room":
        stripeTestPromise = loadStripe(David);
        break;
      case "Mario's Pad":
        stripeTestPromise = loadStripe(David);
        break;
      case "Sentinel":
        stripeTestPromise = loadStripe(PJInvestments);
        break;
      case "Marigold":
        stripeTestPromise = loadStripe(Phill);
        break;
      case "Landstrom":
        stripeTestPromise = loadStripe(Phill);
        break
      case "Golfers Retreat":
        stripeTestPromise = loadStripe(Phill);
        break;
      case "The Pool House":
        stripeTestPromise = loadStripe(RCHomes);
        break;
      case "The lake house":
        stripeTestPromise = loadStripe(RockCity);
        break;

    default:
      console.log("PROPERTY HAS NOT STRIPE OWNER");
      break;
  }

  useEffect(()=>{
    console.log(props.balances);
  },[])

  return (
    <Elements stripe={stripeTestPromise}>
        <PaymentForm 
          balance={props.balance} 
          balances={props.balances}
          propertyName={props.propertyName} 
          propertyAddress={props.propertyAddress}
          customer={props.customer}
          name={props.name}
          lastName={props.lastName}
          email={props.email}
          phone={props.phone}
          checkIn={props.checkIn}
          checkOut={props.checkOut}
          propertyID={props.propertyID}
          propertyPicture={props.propertyPicture}
          selectedID={props.selectedID}
          checkTimes={props.checkTimes}
          imageUpload={props.imageUpload}
          guests={props.guests}
          bedrooms={props.bedrooms}
          bathrooms={props.bathrooms}
        />
    </Elements>
  )
}

export default StripeContainer
