import React, { useState, useEffect, useRef } from 'react'
import Hero from './components/Hero.js'
import Information from './components/Information.js'
import SliderReview from './components/SliderReview.js'
import { FaDoorOpen, FaToilet ,FaUsers} from "react-icons/fa";
import ReactGA from "react-ga4";

function Home() {
  const apiURL = process.env.REACT_APP_APIURL;
  const mailerURL = process.env.REACT_APP_MAILERURL;
  const stripeURL = process.env.REACT_APP_STRIPEURL


  const [selectedID, setSelectedID] = useState(0);
  const [showBooking, setShowBooking] = useState(false);
  // const [property, setProperty] = useState();
  const [myListings, setMyListings] = useState([])

  function showProperty(id) {
    setShowBooking(true);
    setSelectedID(id)
  }

  useEffect(()=>{
    if(showBooking){
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  },[showBooking])


useEffect(()=> {
    fetch(apiURL + '/api/getProperties')
    .then(response => response.json())
    .then(response =>{setMyListings(response.results)})
  }, [])
    
  //ARROWS TO SCROLL THE CAROUSEL 
  const scrollContainerRef = useRef(null);
  const handleScrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 350; 
    }
  };
  const handleScrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 350;
    }
  };

  return (
    <div>
        <Hero listings={myListings}/>
        <div className='title-properties'>
          <h2> The Most Visited </h2> 
          <a href={"/rentals"}> <button> View All <i className="bi bi-caret-right-fill"></i> </button> </a>
        </div> 
        <button onClick={()=>handleScrollLeft()} className='arrows'> <i className="bi bi-chevron-left"></i> </button>
        <div className="carousel" ref={scrollContainerRef}>
          {Object.keys(myListings).map((key) => {
            if(myListings[key].isListed == true){
              const { _id, pictures, nickname, title, address, publicDescription, bedrooms, bathrooms, accommodates,  prices } = myListings[key];
              return (
                <div key={key}>
                  <div className="property">
                    <img src={pictures[0].original} />
                    <p className="nickname"> {title} </p>
                    <p className="address"> <i className="bi bi-geo-alt"></i> {address.city}, {address.state}</p>
                    <div className="capacities">
                      <div className='capacities-container'> 
                        <FaUsers className="icon" />    
                        <p className="quantity">{accommodates}</p> people  
                      </div>
                      <div className='capacities-container'> 
                        <FaDoorOpen className="icon"/>  
                        <p className="quantity">{bedrooms} </p> bedrooms  
                      </div>
                      <div className='capacities-container'> 
                        <FaToilet className="icon"/>    
                        <p className="quantity">{bathrooms}</p> bathrooms 
                      </div>
                    </div>
                    <a href={"/property?id=" + key}> <button> Read More </button> </a>
                  </div>
                </div>
              );
            }
          })} 
        </div>
        <button onClick={()=>handleScrollRight()} className='arrows'> <i className="bi bi-chevron-right"></i> </button>
        <Information />
        <SliderReview />
        <div className='wrapper-whyRecommend'>
          <div>
            <h2> Why People Recommend Better Stays </h2>          
            <h3> State-Of-The-Art Entertainment </h3>
            <p className='why'> From indoor pools and hot tubs, to large flat screen TV’s and arcade game rooms, our homes offer stays unlike any other out there. The entertainment that you will experience at Better Stays is truly unlike any other. </p>
            <h3> High-End & Luxurious </h3>
            <p className='why'> Nothing says luxury like being able to walk into a home and it’s clean, tidy, and spacious. We take pride in our homes, and ensure that they are in an immaculate condition when you arrive. Every piece of furniture was selected to ensure comfort and luxury. We offer all of this without breaking the bank.</p>
            <h3> Perfect For Families </h3>
            <p className='why'> There is something truly special about getting away with your family. Where a hotel separates you from the family experience, our homes bring you closer together with shared spaces to create great memories. Book today to give your family the vacation of a lifetime! </p>          
          </div>
          <div>
            <img src='https://res.cloudinary.com/dxfi1vj6q/image/upload/v1688675149/jw0o9msevuq0hvzuqld2_1_2_dm0nfz.jpg' />
          </div>
        </div>
    </div>
  )
}

export default Home