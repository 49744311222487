import React from 'react'
import { MapContainer, TileLayer, Marker, Popup, Circle } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

function Map(props) {
  if (props.showLocation == true){
    return (
      <div className='wrapper-map' onClick={()=> window.location.href = 'https://google.com/maps/place/' + props.lat +  "," + props.long}>
        <MapContainer center={[props.lat, props.long]} zoom={18} scrollWheelZoom={true} style={{ height: '400px', borderRadius: '10px', boxShadow: ' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Circle center={[props.lat, props.long]} radius={1} />
        </MapContainer>
      </div>
    )
  } else {
  return (
      <div className='wrapper-map'>
        <MapContainer center={[props.lat, props.long]} zoom={16} scrollWheelZoom={false} style={{ height: '400px', borderRadius: '10px', boxShadow: ' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Circle center={[props.lat, props.long]} radius={100} />
        </MapContainer>
      </div>
  )
  }
}

export default Map
