import React, { useState, useEffect } from 'react'
import {storage} from "./components/Firebase"
import {ref, uploadBytes} from "firebase/storage"
import { auth, provider } from './components/Firebase';
import {signInWithPopup} from "firebase/auth"
import { useFetcher } from 'react-router-dom';

function Login() {
  const [reservations, setReservations] = useState([])
  const [reservationID, setReservationID] = useState('')


  useEffect(()=>{
    getReservations()
  },[])

  async function getReservations(){
    await fetch('https://apis-betterstay.vercel.app/api/getReservations')
    .then(response => response.json())
    .then(response => setReservations(response.results))
  }

  const googleAuth = () => {
    signInWithPopup(auth, provider).then((data)=>{
      validGoogleReservation(data.user.displayName, data.user.email)
    })
  }

  function validGoogleReservation(fullName, email){
    let found = false
    for(let i = 0; i < reservations.length; i ++){
      if(reservations[i].guest.fullName == (fullName)){
        console.log('RESERVATION FOUND');
        found = true;
        console.log(reservations[i]._id);
        setTimeout(() => {
          window.open('/reservation?id=' + reservations[i]._id + '&&email=' + email, '_blank');
      })
      
      }
    }
    if(!found) {
      alert("Reservations Not Found :(")
    }
  }

  function validIDReservation(){
    let found = false;
    for(let i = 0; i < reservations.length; i ++){
      if(reservations[i]._id == reservationID){
        found = true;
        window.location.href = '/reservation?id=' + reservations[i]._id ;
      }
    }
    if(!found){
      alert("Reservations Not Found :(")
    }
  }


  return (
    <div className='wrapper-login'>
      <div className='login-form'>
        <h2> Find your reservation</h2>
        <input type='text' placeholder='Reservation ID' onChange={(e)=>setReservationID(e.target.value)}/>
        <button onClick={()=>validIDReservation()}> Check my reservation </button>
        <p> or </p>
        <div className='googleSignIn' onClick={()=>googleAuth()}>
              <img className="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
              <p> Login With Google</p>
        </div>
      </div> 
    </div>
  ) 
}

export default Login
