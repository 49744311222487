import React from 'react'

function Loader(props) {
  return (
    <div className='wrapper-loader'>
        <div className='bouncingCalendar'>
            <i className={props.type == 'reservations'? "calendarIcon bi bi-houses":"calendarIcon bi bi-calendar4-week"}></i>
        </div>
        
        <h2> {props.type == 'reservations'? "Loading Reservations ...":"Loading Dates..."}</h2>
        <br></br><br></br>
        <div className="loading-bar">
            <div className="loading-bar-progress"></div>
        </div>
    </div>
  )
}

export default Loader
