import React from 'react'

function Footer() {
  return (
    <div className='wrapper-footer' id="wrapper-footer">
      <div id="left">
        <img src="https://res.cloudinary.com/dxfi1vj6q/image/upload/v1683933526/Final_Logo_1_byrdtx_m9colt.png"></img>
        <a href='tel:+18152009715'> +1 (815) 200 9715 </a>
        <a href='mailto:betterstaynow@gmail.com'>  betterstaynow@gmail.com </a>
        <br></br>
        <p id="footer-legend">Better Stays is the Midwest's premier vacation rental company. Don't just stay anywhere, stay better! </p>
      </div>
      <div>
        <div className='newsLetter'>
            <input type='text' placeholder='email@example.com'></input>
            <button> Submit </button>
        </div>
        <p id="noSpam"> *Get the lastest information and deals by just signing up your email with us. No Spam Btw. </p>
        <div className="menu">
          <a href='/'> Home </a>
          <a href='/rentals'> Rentals </a>
          <a href='/services'> Services </a>
          <a href='/contact'> Contact </a>
          {/* <a href='/login'> Login </a> */}
          <a href='/terms-and-conditions'> T & C </a>
          {/* <a href='https://dashboard-better-stays.vercel.app/'> Access </a> */}
        </div>
      </div>

    </div>
  )
}

export default Footer
