import React, { useEffect, useState } from "react";
import Booking from "./components/Booking";
import { FaDoorOpen, FaToilet ,FaUsers} from "react-icons/fa";

function Rentals() {
  const apiURL = process.env.REACT_APP_APIURL;
  const mailerURL = process.env.REACT_APP_MAILERURL;
  const stripeURL = process.env.REACT_APP_STRIPEURL
  
  const [selectedID, setSelectedID] = useState(0);
  const [showBooking, setShowBooking] = useState(false);
  const [property, setProperty] = useState();
  const [myListings, setMyListings] = useState([])
  const [selectedState, setSelectedState] = useState("");
  const [selectedValue, setSelectedValue] = useState("Select a state")

  function showProperty(id) {
    setShowBooking(true);
    setSelectedID(id)
  }

  useEffect(()=>{
    if(showBooking){
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  },[showBooking])

  useEffect(()=> {
    fetch(apiURL + '/api/getProperties')
    .then(response => response.json())
    .then(response => setMyListings(response.results))
  }, [])

  // useEffect(()=>{
  //   console.log(myListings);
  // },[myListings])

  function handleStateChange(event) {
    setSelectedState(event.target.value);
  }

  return (
    <div>
      <div className="filter">
        <h2> {!selectedState == "" ? selectedState : "All Our Rentals"}</h2>
        <select onChange={handleStateChange}>
          <option value={selectedValue} disabled={true}> Select a state </option>
          <option value={''}> Show All </option>
          <option value={'Illinois'}> Illinois </option>
          <option value={'Indiana'}> Indiana </option>
          <option value={'Wisconsin'}> Wisconsin </option>
        </select>
      </div>
      <div className="wrapper-properties">
       {Object.keys(myListings).map((key) => {
          if(myListings[key].isListed == true){
            const { _id, pictures, nickname, title,  address, publicDescription, bedrooms, bathrooms, accommodates,  prices } = myListings[key];
            return (
              <div key={key}  style={{display: address.state.includes(selectedState) ? "block" : "none"}}>
                <div className="property">
                  <img src={pictures[0].original} />
                  <p className="nickname"> {title} </p>
                  <p className="address"> <i className="bi bi-geo-alt"></i> {address.city}, {address.state}</p>
                  <div className="capacities">
                      <div className='capacities-container'> 
                        <FaUsers className="icon" />    
                        <p className="quantity">{accommodates}</p> people  
                      </div>
                      <div className='capacities-container'> 
                        <FaDoorOpen className="icon"/>  
                        <p className="quantity">{bedrooms} </p> bedrooms  
                      </div>
                      <div className='capacities-container'> 
                        <FaToilet className="icon"/>    
                        <p className="quantity">{bathrooms}</p> bathrooms 
                      </div>
                    </div>
                  <a href={"/property?id=" + key}> <button> Read More </button> </a>
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  )
}

export default Rentals;
