import React, { useEffect, useState } from 'react'
import { db } from "./components/Firebase";
import {collection, getDocs, addDoc, updateDoc, doc, deleteDoc, query, where, setDoc} from "firebase/firestore";



function Support() {

  const [questions, setQuestions] = useState([]);
  const usersCollectionRef = collection(db, "Questions");

  const [newQuestion, setNewQuestion] = useState("");
  const [questionSent, setQuestionSent] = useState(false)


  useEffect(() => {
    fetchQuestions();
  }, []);


  async function fetchQuestions() {
      const data = await getDocs(usersCollectionRef);
      setQuestions(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      console.log(questions);
  };

  async function sendQuestion(){
    setQuestionSent(true)
    await addDoc(collection(db, "Questions"), {
      question: newQuestion, 
      answer: "", 
      isAnswered: false
    });
  }


  return (
    <div className='wrapper-support'>
      <div className='question-container'>
        <h2> How can we help you ?</h2>
        <div className='field-question'>
            <input  onChange={(e) => setNewQuestion(e.target.value)} type='text' placeholder='What is your question?'></input>
            <button onClick={()=> sendQuestion()}> Send </button>
        </div>
      </div>


      <div className='questions'>
        {questions.map((question) => {
          if(question.isAnswered == true){
            return(
              <div className="question" key={question.id}>
                <h3>{question.question}</h3>
                <p>{question.answer}</p>
              </div>
            )
          }
        }
       )}
      </div>

      <div className='questionConfirmation' style={{display: questionSent? "flex":"none"}}>
          <i className="bi bi-check-circle-fill iconCheck"></i>
          <h2> Thanks !</h2>
          <p> Your question has been sent successfully </p>
      </div>
    </div>
  )
}

export default Support
