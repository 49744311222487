import React, { useDebugValue, useEffect, useState } from "react";
import { db } from "./Firebase";
import {collection, getDoc, addDoc, updateDoc, doc, deleteDoc, query, where, setDoc} from "firebase/firestore";


function PrintableReservation() {
    const apiURL = process.env.REACT_APP_APIURL;
    const mailerURL = process.env.REACT_APP_MAILERURL;
    const stripeURL = process.env.REACT_APP_STRIPEURL;

    const [reservationID, setReservationID] = useState('')
    const [reservation, setReservation] = useState([])
    const [property, setProperty] = useState([])
    const [data, setData] = useState([])
    const [checkIn, setCheckIn] = useState('')
    const [checkOut, setCheckOut] = useState('')

    const [dataIsLoaded, setDataIsLoaded] = useState(false)
    const [propertyIsLoaded, setPropertyIsLoaded] = useState(false)


    const searchParams = new URLSearchParams(window.location.search);

    useEffect(()=>{
        setReservationID(searchParams.get("id"));  
        if(reservationID !== ''){
            getReservation()
            getData()
        } else {
            console.log('Getting ID ...');
        }
    },[reservationID])



    async function getReservation(){
        await fetch(apiURL + '/api/getReservations')
        .then(response => response.json())
        .then(response => {
            let reservations = response.results
            for (let i =0 ; i < reservations.length; i ++){
                if(reservations[i]._id == reservationID){
                    console.log('RESERVATION :');
                    console.log(reservations[i]);
                    setReservation(reservations[i])
                    getProperty(reservations[i].listingId)
                }
            }
        })
    }

    async function getProperty(propertyID){
        await fetch(apiURL + '/api/getProperties')
        .then(response => response.json())
        .then(response => {
            let properties = response.results;
            for(let i = 0; i < properties.length; i ++){
                if(properties[i]._id == propertyID){
                    console.log('PROPERTY DATA:');
                    console.log(properties[i]);
                    setProperty(properties[i])
                    setPropertyIsLoaded(true)
                }
            }  
        })
    }

    function renderAmenities(array){
      return Object.keys(array).map((key) => (
        <p key={key} className='amenitie'>
          {array[key]}
        </p>
      ))
    }

    function formatDate(value){
      const date = new Date(value);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-US', options);
      return formattedDate;
    }


    // ======== ONLY FOR THE PRINTABLE VERSION  ========== //

    useEffect(()=>{
        document.getElementById('wrapper-header').style.display = "none";
        document.getElementById('wrapper-footer').style.display = "none";
    },[])

    
    async function getData(){
        const docRef = doc(db, "Documents", reservationID);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
            console.log(docSnap.data());
          setData(docSnap.data())
          setCheckIn(formatChecks(docSnap.data().input[12] + ', 20' + docSnap.data().input[13]))
          setCheckOut(formatChecks(docSnap.data().input[16] + ', 20' + docSnap.data().input[17]))
          setDataIsLoaded(true)
        //   window.print()
        } else {
            alert("FIREBASE ERROR")
        }
    }

    function formatChecks(str){
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(str).toLocaleDateString(undefined, options);
        return formattedDate;
    }


    function NightsBetween(dateStr1, dateStr2) {
        const startDate = new Date(dateStr1);
        const endDate = new Date(dateStr2);
        if (isNaN(startDate) || isNaN(endDate)) {
          return "Invalid date format";
        }
        const timeDifference = endDate.getTime() - startDate.getTime();
        const numberOfNights = Math.ceil(timeDifference / (1000 * 3600 * 24));
        return numberOfNights;
    }

      

      
    return (
        <div className="wrapper-printableReservation">             
         {dataIsLoaded && propertyIsLoaded ? (
            <div>
            <div className="header">
                <img className="logo-header"src="https://res.cloudinary.com/dxfi1vj6q/image/upload/v1682964660/Final_Logo_1_byrdtx.png" onLoad={()=>window.print()}/>
                <div>

                </div>
            </div>
            <h1> Confirmed: {NightsBetween(data.input[12] + ', 20' + data.input[13], data.input[16] + ', 20' + data.input[17])} nights at {data.propertyName}</h1>
            <div className="bookingDetails">
                <div id="left">
                    <p><b>Booked by: </b> {data.customerName + ' ' + data.customerLastName}</p>
                    <p>{formatDate(data.date)}</p>
                </div>
                <div id="right">
                    <p><b>Accepted</b></p>
                    <p>{reservationID}</p>
                </div>
            </div>
            <div className="reservationDetails">
                <div>
                    <div className="container1">
                        <div className="dates">
                            <div>
                                <h2> Check- In </h2>
                                {checkIn}
                            </div>
                            <i className="bi bi-chevron-compact-right chev"></i>
                            <div>
                                <h2> Check-Out </h2>
                                {checkOut}
                            </div>
                        </div>
                        <hr></hr>
                        <p id="description"> {property.publicDescription.summary}</p> 
                        <hr></hr>
                        <p className="hosted"> <b>Hosted by: </b> {data.input[49]}</p>
                        <p className="hosted"> <b> Phone: </b>{data.input[50]}</p>
                        <p className="hosted"> <b> Email: </b> betterstaynow@gmail.com</p>
                    </div>
                </div>
                <div>
                    <div className="container2">
                        <h2> Charges </h2>
                        <p><b>Nights: </b> {parseFloat(data.input[26]).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, useGrouping: true,})} USD</p>
                        <p><b>Cleaning Fee: </b> {parseFloat(data.input[38]).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, useGrouping: true,})} USD</p>
                        <p><b>Taxes: </b> {parseFloat(data.input[39]).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, useGrouping: true,})} USD</p>
                        <hr></hr>
                        <p id="total"><b>Total: </b> {(parseFloat(data.input[26]) + parseFloat(data.input[38]) + parseFloat(data.input[39])).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, useGrouping: true,})} USD</p>
                    </div>
                    <div className="container3">
                        <h2> Property </h2>
                        <p> <b>Name: </b> {data.propertyName} </p>
                        <p> <b>Address: </b> {data.input[6]} </p>
                        <p> <b>Bedrooms: </b> {data.input[9]} </p>
                        <p> <b>Badrooms: </b> {data.input[10]} </p>
                    </div>
                </div>
            </div>
        </div>
         ) : (
          <div className="loader">Loading...</div>
        )}
      </div>
    )         
}
      

export default PrintableReservation
