import React, { useState } from 'react';


function Services() {
  const faqData = [
    {
      question: 'Why should I choose a Better Stay Rental over a hotel?',
      answer: "Travel, particularly with families or groups, is about the experience and the memories you make with the ones you care about. Hotels can help locate everyone near each other, but Better Stay rentals really bring people together. We are much more than a sleeping accommodation and in most situations we provide a far greater value for your money. Why pay more per person for multiple hotel rooms that are essentially small rooms to sleep in when you can take advantage of full homes that offer the advantages of privacy, luxury, entertainment, and the freedom to enjoy each other's company together?! There's truly nothing comparable to the ability to kick back and unwind as a family, with the peace of mind that everything has been taken care of for you. ",  
    },
    {
      question: 'Where are your properties located?',
      answer: 'We currently have ten short term rental properties in the Midwest. We have eight properties in multiple locations within Illinois including Chicago,Rockford, and Oak Park. Our locations outside of Illinois include Janesville, Wisconsin & Terre Haute, Indiana.',
    },
    {
      question: 'Do I have to bring anything along?',
      answer: 'Apart from your personal belongings, you don’t need to bring anything along. Our homes are catered to serve any and all of your needs. From fresh towels, to clean bedding, and even fully-stocked kitchen utensils, all the way to pool toys. Just bring yourself and fully relax into the entire experience.',
    },
    {
      question: 'Do we have to pay for the arcade games?',
      answer: 'Not at all! It’s all included in the price. Our Arcade is completely free to play, and will ensure that you and the kids will have hours of fun. Everything is part of the package and the experience, to make your stay a memorable experience.',
    },
    {
      question: 'Do you provide Long-Stay services?',
      answer: 'We do! All of our properties are rentable for 28 days or more. If you need any special arrangements, reach out to us and we would be more than happy to assist you with any plans or needs that you have.',
    },
    {
      question: 'Cancellation policy',
      answer: 'To receive a full refund, guests must cancel within 48 hours of booking, and the cancellation must occur at least 30 days before check-in.'
    },
  ]

  const [section, setSection] = useState(1); 

  function openBetterEvents(){
    window.open('https://bettereventsco.com', '_blank');
  }
  
  return (
    <div className='wrapper-services services'>
       <div className="introduction">
        <div id="left">
          <p>Rentals With A Difference</p>
          <h2>Luxury Service At Affordable Pricing</h2>
          <p>Enjoy our top-of-the-line service at Better Stays.</p>
          <button className="btnServices" onClick={()=>openBetterEvents()}> Learn More </button>
        </div>
        <div id="right"> 
        <img
            src="https://res.cloudinary.com/dxfi1vj6q/image/upload/v1705551021/WhatsApp_Image_2024-01-12_at_15.51.02_bxijto.jpg"
            width={500}
            height={450}
          />
        </div>  
       </div>
      <div className="highlights">
       <div className='container' onClick={()=>openBetterEvents()}> 
          <img
            src="https://res.cloudinary.com/dxfi1vj6q/image/upload/v1669745173/rfqeff_naglsr.png"
            width={50}
            height={50}
          />
          <h2>Our Inflatables</h2>
          <p>We partner with Better Events to provide you with an unparalleled experience. We deliver the epitome of fun and excitement, offering an inflatable adventure that brings joy to every moment. From thrilling slides & bounce houses, to obstacle courses & Interactive games, our inflatables ensure a complete escape into a world  of entertainment.</p>
        </div>
        <div className='container'> 
          <img
            src="https://res.cloudinary.com/dxfi1vj6q/image/upload/v1669745173/Group_237560_mqgkgy.png"
            width={50}
            height={50}
          />
          <h2>Luxury &amp; Comfort</h2>
          <p>Our properties were designed to give you the best experience possible. We offer ultra-luxury enjoyment at our homes, giving you that complete home-away-from-home feel. Not only will your entire family love it, but it would be the best possible accommodation that you can find in the Midwest.</p>
        </div>
        <div className='container'> 
          <img
            src="https://res.cloudinary.com/dxfi1vj6q/image/upload/v1669745173/Group_fwr_xtpsnt.png"
            width={50}
            height={50}
          />
          <h2>Free-Play Arcade</h2>
          <p> Our accommodations have been carefully crafted to ensure that you have an exceptional experience. We provide a truly luxurious and comfortable stay, making you feel right at home while away from home. Our properties are not only adored by families, but they also offer the finest lodging options available in the Midwest.</p>
        </div>
        <div className='container'> 
          <img
            src="https://res.cloudinary.com/dxfi1vj6q/image/upload/v1669745173/rfqeff_naglsr.png"
            width={50}
            height={50}
          />
          <h2>Group &amp; Extended-Stay Friendly</h2>
          <p> Our homes can accommodate up to 20 guests depending on the location. This makes our properties the perfect place for families, groups of friends, or even excursions. We also offer extended stays, allowing you to book our properties for longer periods of time depending on your needs. </p>
        </div>
      </div>

      
      <div className="whyChooseUs">
        <h2>Why Should You Choose Us?</h2>
        <p>Our rental properties provide a comfortable, safe, and stylish stay with all the amenities you need for a perfect vacation. We strive to ensure that you have the best experience possible while with us at Better Stays.</p>
      </div>

      <div className="insights">
        <div className='container'>
          <img
            src="https://res.cloudinary.com/dxfi1vj6q/image/upload/v1669748598/gaveaeb_urimri.png"
            width={50}
            height={50}
            className="insight-icon"
          />
          <h2>Affordable Prices</h2>
          <p>All of our homes deliver exceptional value for money at great prices! With ultra-luxury, privacy, and entertainment, our homes are the best choice for families and groups.</p>
        </div>
        <div className='container'>
          <img
            src="https://res.cloudinary.com/dxfi1vj6q/image/upload/v1669748598/gaveaeb_urimri.png"
            width={50}
            height={50}
            className="insight-icon"
          />
          <h2>Beautiful Properties</h2>
          <p>We take pride in our properties and know that each home will give you the vacation experience of a lifetime. From our soft linen to our state-of-the-art kitchens, and even our arcade games and our pools. Everything was designed to bring you the best.</p>
        </div>
        <div className='container'>
          <img
            src="https://res.cloudinary.com/dxfi1vj6q/image/upload/v1669748598/gaveaeb_urimri.png"
            width={50}
            height={50}
            className="insight-icon"
          />
          <h2>Better Than A Hotel</h2>
          <p>Get a 5-star experience, at rates that are cheaper than hotels, with more benefits and amenities. With everything that our homes have to offer your family, we beat any hotel in overall value and amenities.</p>
        </div>
      </div>
 
      <div className="results" >
        <div id="left">
          <img
            src="https://res.cloudinary.com/dxfi1vj6q/image/upload/v1688675907/vn3jvthxrxyiobqi5izo_xlr5so.jpg"
            width={500}
            height={430}
            className={'img-result'}
          />
          <div className='two-columns'>
            <img
              src="https://res.cloudinary.com/dxfi1vj6q/image/upload/v1688675906/v5kqbtbe8qz7vkgvkuao_blsu01.jpg"
              width={240}
              height={200}            
            />
            <img
              src="https://res.cloudinary.com/dxfi1vj6q/image/upload/v1688675906/original_675147189760427740_1452009741_whi4zo.jpg"
              width={240}
              height={200}            
            />
          </div>
        </div>
        <div id="right">
          <h2>Your Comfort Is Our Priority</h2>
          <p>When it comes to your comfort, we never take shortcuts. We’re dedicated to ensuring you’re always well taken care of, from the moment you walk through our doors until the moment you leave. We want you to feel relaxed and at ease, so you can focus on enjoying your stay.</p>
          <div className='indicators' id="top-indicators">
            <div className='indicator'>
              <h2>400+</h2>
              <p>Happy Customers</p>
            </div>
            <div className='indicator'>
              <h2>10</h2>
              <p>Available Properties</p>
            </div>
          </div>
          <div className='indicators'>
            <div className='indicator'>
              <h2>5</h2>
              <p>Different Cities</p>
            </div>
            <div className='indicator'>
              <h2>20</h2>
              <p>Max Group size</p>
            </div>
          </div>
        </div>
      </div>

      <div className="faq">
        <h2> Frequently Asked Questions </h2> 
        <div className='row'>
          <h3> {faqData[0].question} </h3>
          <p> {faqData[0].answer} </p>
        </div>
        <div className='row'>
          <h3> {faqData[1].question} </h3>
          <p> {faqData[1].answer} </p>
        </div>
        <div className='row'>
          <h3> {faqData[2].question} </h3>
          <p> {faqData[2].answer} </p>
        </div>
        <div className='row'>
          <h3> {faqData[3].question} </h3>
          <p> {faqData[3].answer} </p>
        </div>
        <div className='row'>
          <h3> {faqData[4].question} </h3>
          <p> {faqData[4].answer} </p>
        </div>
        <div className='row'>
          <h3> {faqData[5].question} </h3>
          <p> {faqData[5].answer} </p>
        </div>
      </div>
    </div>
  )
}

export default Services
