import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import axios from "axios"
import React, { useEffect, useState } from 'react'
import {storage} from "./Firebase"
import {ref, uploadBytes} from "firebase/storage"
import { app } from './Firebase';
import { getFirestore, addDoc, collection, doc, getDoc, onSnapshot, updateDoc, setDoc } from "firebase/firestore";
import cardGif from '../images/creditCard.gif'

const CARD_OPTIONS = {
	style: {
        base: {
          color: "#32325d",
          fontFamily: "Arial, sans-serif",
          fontSmoothing: "antialiased",
          fontSize: "20px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        icon: {
          color: "#0089BF",
        },
    },
};

function PaymentForm(props) {
  const apiURL = process.env.REACT_APP_APIURL;
  const mailerURL = process.env.REACT_APP_MAILERURL;
  const stripeURL = process.env.REACT_APP_STRIPEURL;

    const db = getFirestore(app);
    const [success, setSuccess ] = useState(false)
    const stripe = useStripe()
    const elements = useElements()

    const [showBtn, setShowBtn] = useState(true);
    const [failed, setFailed] = useState(false);
    const [bookingDates, setBookingDates] = useState([]);
    const [imageUpload, setImageUpload] = useState(props.imageUpload)
    const [showLoader, setShowLoader] = useState(false)
  

    // useEffect(()=>{
    //   console.log(props.balances);
    // },[])


   const handleSubmit = async (e) => {
    fetch(apiURL + '/api/getLastAvailability',{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        propertyID: props.propertyID,
        checkIn: props.checkIn.split('/').join('-'),
        checkOut: props.checkOut.split('/').join('-')
      })
    })
    .then(response => response.json())
    .then(response => {
      for(let i = 0; i < response.data.days.length; i ++){
        if(response.data.days[i].status == "booked"){
          setShowLoader(false)
          if (window.confirm("Some Dates are not longer Available :(")) {
            window.location.reload();
          }
          break;
        }
      }
    // console.log("All dates are Available");
    })
      // PROCESSING PAYMENT WITH STRPE
      e.preventDefault()
      const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
      })
      if(!error) {
          try {
              const {id} = paymentMethod
              let stripeOwner;

              switch (props.propertyName) {
                case "Tuneberg":
                  stripeOwner = 'diego'
                  break;
                  case " Oak Park Game Room":
                    stripeOwner = 'David'
                    break;
                  case "Mario's Pad":
                    stripeOwner = 'David'
                    break;
                  case "Sentinel":
                    stripeOwner = 'PjInvestments'
                    break;
                  case "Marigold":
                    stripeOwner = 'Phill'
                    break;
                  case "Landstrom":
                    stripeOwner = 'Phill'
                    break
                  case "Golfers Retreat":
                    stripeOwner = 'Phill'
                    break;
                  case "The Pool House":
                    stripeOwner = 'RcHomes'
                    break;
                  case "The lake house":
                    stripeOwner = 'RockCity'
                    break;
            
                default:
                  console.log("PROPERTY HAS NOT STRIPE OWNER");
                  break;
              }

              const response = await axios.post(stripeURL + "/payment" + stripeOwner, {
                id, 
                amount: Math.floor(props.balance*100),
                // amount: 100, 
                propertyName: props.propertyName, 
                description: props.propertyName + " - " + props.customer,
              })
              // console.log(response);

              if (response.data.success) {
                // console.log("Payment Successfull !");
                await newReservation()
                await uploadID();
                setShowLoader(false)
                setSuccess(true)
                setTimeout(function() {
                  window.location.href = "?id=" + props.selectedID + "&&confirmed=true";
                }, 1000);
              } else {
                // console.log(response);
                setShowLoader(false)
                setShowBtn(true)
                setFailed(true)
              }
          } catch (error) {
            console.log("Error", error)
          }
      } else {
          console.log(error.message)
          setShowLoader(false)
      }
   }

  //  async function newReservation(){
  //   let reservationId = '';
  //   await fetch(apiURL + '/api/newReservation', {
  //       method: 'POST',
  //       body: JSON.stringify({ 
  //         name: props.name,  
  //         lastName: props.lastName,
  //         phone: props.phone, 
  //         email: props.email, 
  //         checkIn: props.checkIn,
  //         checkOut:props.checkOut,
  //         propertyID: props.propertyID,
  //         paid : (props.balance).toFixed(2)
  //     }), headers: {'Content-Type': 'application/json'}})
  //   .then(response => response.json())
  //   .then(response => reservationId = response)
  //   await createContract(reservationId)
  //  }

  async function newReservation() {
    try {
        const response = await fetch(apiURL + '/api/newReservation', {
            method: 'POST',
            body: JSON.stringify({ 
                name: props.name,  
                lastName: props.lastName,
                phone: props.phone, 
                email: props.email, 
                checkIn: props.checkIn,
                checkOut: props.checkOut,
                propertyID: props.propertyID,
                paid: props.balance.toFixed(2)
            }),
            headers: {'Content-Type': 'application/json'}
        });

        if (!response.ok) {
            throw new Error('Failed to create reservation');
        }

        const reservationId = await response.json();
        await createContract(reservationId);
    } catch (error) {
        console.error('Error creating reservation:', error);
        // Handle error as needed
    }
}


   //Submiting ID
   async function uploadID(){
    const imageRef= ref(storage, `customersID/${props.email}`)
    uploadBytes(imageRef,imageUpload).then(()=>{ 
    })
   }
   useEffect(()=>{
    if(props.imageUpload){
      setImageUpload(props.imageUpload)
    }
   })


  function convertDateToText(dateInNumber){
    const date = new Date(dateInNumber); // Use a specific year (e.g., 2023)
    const day = date.getDate()
    const monthName = date.toLocaleString('en-US', { month: 'long' });
    return {day, monthName };
  }
  function formatDate(date) {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }
  async function getLandlord(property){
    const docRef = doc(db, "Landlords", property);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
     return {
      fullName:docSnap.data().fullName, 
      phone:docSnap.data().phone, 
      email:docSnap.data().email, 
      address:docSnap.data().address,
      mailingAddress:docSnap.data().mailingAddress
      }
    }
  }
  async function createContract(id){
    let currentDay = new Date().getDate()
    let currentMoth = new Date().toLocaleString('en-US', {month: 'long'});
    let landlord = await getLandlord(props.propertyName);
    let checkIn = convertDateToText(props.checkIn)
    let checkOut = convertDateToText(props.checkOut)

    let cleaningFee = 0;
    for(let i = 0; i < props.balances.length; i ++ ){
      if(props.balances[i].date == 'Cleaning Fee'){
        cleaningFee = props.balances[i].price;
      }
    }

    let cityTax = 0
    let localTax = 0;
    for(let i = 0; i < props.balances.length; i ++ ){
      if(props.balances[i].date == 'City Tax'){
        cityTax = props.balances[i].price;
      }
      if(props.balances[i].date == 'Local Tax'){
        localTax = props.balances[i].price;
      }
    }

    const inputsArray = [];
    for(let i = 0; i < 60; i++){
      inputsArray.push('');
    }
    inputsArray[1] = currentMoth + ', ' +  currentDay 
    inputsArray[2] = props.checkOut.substring(2, 4)
    inputsArray[3] = props.name + ' ' + props.lastName
    inputsArray[5] = landlord.fullName
    inputsArray[6] = landlord.address
    inputsArray[7] = landlord.mailingAddress
    inputsArray[9] = props.bedrooms
    inputsArray[10] = props.bathrooms
    inputsArray[12] = checkIn.day + ', ' + checkIn.monthName
    inputsArray[13] = props.checkIn.substring(2, 4)
    inputsArray[14] = props.checkTimes[0].split(':')[0]
    inputsArray[15] = '00'
    inputsArray[16] = checkOut.day + ', ' + checkOut.monthName
    inputsArray[17] = props.checkOut.substring(2, 4)
    inputsArray[18] = props.checkTimes[1].split(':')[0]
    inputsArray[19] = '00'
    inputsArray[23] = '22'
    inputsArray[24] = '00'
    inputsArray[25] = props.guests.toString()
    inputsArray[26] = props.balance.toFixed(2)
    inputsArray[38] = cleaningFee.toString()
    inputsArray[39] = (cityTax + localTax).toFixed(2)
    inputsArray[49] = landlord.fullName
    inputsArray[50] = landlord.phone
    inputsArray[51] = landlord.email
    
    const radiosArray = Array(42).fill(false);
    radiosArray[2] = true
    radiosArray[5] = true
    radiosArray[7] = true
    radiosArray[8] = true
    radiosArray[12] = true
    radiosArray[14] = true
    radiosArray[15] = true
    radiosArray[17] = true
    radiosArray[19] = true
    radiosArray[26] = true
    radiosArray[27] = true
    radiosArray[28] = true
    radiosArray[31] = true
    radiosArray[34] = true
    radiosArray[38] = true
    radiosArray[39] = true


    // const docRef = await addDoc(collection(db, "Documents"), {
    //   date: formatDate(new Date()),
    //   input: inputsArray,
    //   nameLandlord: landlord.fullName, 
    //   nameTenant1: "", 
    //   nameTenant2: "",
    //   radios:radiosArray, 
    //   signLandlord: "", 
    //   signTenant1: "",
    //   signTenant2: "",
    //   title: "Contract", 
    //   status: "available",
    //   propertyName: props.propertyName, 
    //   customerEmail: props.email,
    //   customerName: props.name, 
    //   customerLastName: props.lastName,
    // });

    await setDoc(doc(db, "Documents",id), {
      date: formatDate(new Date()),
      input: inputsArray,
      nameLandlord: landlord.fullName, 
      nameTenant1: "", 
      nameTenant2: "",
      radios:radiosArray, 
      signLandlord: "", 
      signTenant1: "",
      signTenant2: "",
      title: "Contract", 
      status: "available",
      propertyName: props.propertyName, 
      customerEmail: props.email,
      customerName: props.name, 
      customerLastName: props.lastName,
      priceBreakDown: props.balances
    });
    
    await sendContract(id)
  }
  async function sendContract(contractID){
      //sending Email  
      console.log("Sending Contract ...");
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      
      var urlencoded = new URLSearchParams();
      urlencoded.append("name", props.name);
      urlencoded.append("lastName", props.lastName);
      urlencoded.append("email", props.email);
      urlencoded.append("contractID", contractID);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };
      
      await fetch(mailerURL + "/api/sendContract", requestOptions)
        .then(response => response.text())
        .then(result => console.log("Email Sent: " + result))
        .catch(error => console.log('== ERROR === ', error));
  }

    return (
        <>
        <div className="paymentLoader" style={{display: showLoader? "block":"none"}}>
          <img src={cardGif} />
          <p> Procesing Payment ... </p>
        </div>
        {!success ? 
        <form onSubmit={handleSubmit}>
            <h3> Payment </h3>
            <div className="card-details">
                <fieldset className="FormGroup">
                    <div className="FormRow">
                        <CardElement options={CARD_OPTIONS}/>
                    </div>
                </fieldset>
            </div>
            <div style={{display: failed? "block":"none"}}>
                <p id="failed"> *Payment Failed </p>
            </div>
            <div style={{display: showBtn? "block":"none"}}>
                <button id="btnPay" onClick={()=>setShowLoader(true)}>Pay ${props.balance.toFixed(2)} USD</button>
            </div>

        </form>
        :
       <div>
           <div className="paymentConfirmation">
                <i className="bi bi-check-circle-fill iconCheck"></i>
                <p> Payment Approved </p>
                <p> You Booking has been confirmed </p>
           </div>
       </div> 
        }     
      </>
    )
}

export default PaymentForm;


