import React, { useState } from 'react'
import {FaEnvelope, FaComments, FaPhoneAlt } from "react-icons/fa";

function Contact() {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
  
    const handleNameChange = (event) => {
      setName(event.target.value);
    };
  
    const handlePhoneChange = (event) => {
      setPhone(event.target.value);
    };
  
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };
  
    const handleMessageChange = (event) => {
      setMessage(event.target.value);
    };
  
    const recipient = 'betterstaynow@gmail.com';
    const subject = 'NEW MESSAGE FROM THE WEBSITE';
    const body = "Name : " +  name + "\n\n Email: " + email  + "\n\n Phone: " + phone  + "\n\n Message: " + message;
  
    const openEmail = (e) => {
      // const mailtoUrl = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      // window.location.href = mailtoUrl;
      const mailtoLink = `mailto:betterstaynow@gmail.com?subject=${encodeURIComponent(
        name
      )}&body=${encodeURIComponent(message)}`;
      window.location.href = mailtoLink;
    };


    const mailtoLink = `mailto:betterstaynow@gmail.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}!`;

    const handleTellClick = () => {
      window.open('tel:+18152009715');
    };
  return (
    <div>
        <div className='contact-grid2'>
            <div>
                <h2> Contact Us </h2>
                <p> If you have any questions, please don’t hesitate to contact us! We’d be more than happy to assist you as soon as we can!  </p>
            </div>
            <div id="form">
              <form onSubmit={openEmail}>
                <div className='inputs-form'>
                  <p> Name: </p>
                  <input className="in-form" type="text"  onChange={handleNameChange} required></input> 
                </div>
                <div className='grid-2'>
                  <div className='inputs-form' id="email">
                    <p> Email: </p>
                    <input className="in-form" type="email" onChange={handleEmailChange} required></input> 
                  </div>  
                  <div className='inputs-form' id="phone">
                    <p> Phone Number: </p>
                    <input className="in-form" type="tel" onChange={handlePhoneChange} required></input> 
                  </div>
                </div>
                <div className='inputs-form'>
                  <p> Message: </p>
                  <textarea className="in-form" type="text" onChange={handleMessageChange} required rows={4}></textarea>
                </div>
               
                <a className='btn-form' href={mailtoLink}>Send Email</a>
              </form> 
            </div>
        </div>
        <div className='hereForYou'>
            <h2> We're here for you </h2>
            <p>We truly believe in exceptional service. If you need help right away, please don’t hesitate to reach out to us via these alternate methods</p>
            <div className='contact-methods'>
              <a href='mailto:betterstaynow@gmail.com' className='method' id="mail"> 
                <FaEnvelope className='contact-icon'/>   
                betterstaynow@gmail.com 
              </a>
              <a href='/support' className='method' id="support"> 
                <FaComments className='contact-icon'/>
                <p> Support </p>
              </a>
              <a href='tel:+16303707422' className='method' id="phone"> 
                <FaPhoneAlt className='contact-icon'/>   
                +1 (800) 200 9715
              </a>
            </div>
        </div>
    </div>

  )
}

export default Contact
