import React, { useState, useEffect, useRef } from 'react'
import StripeContainer from './StripeContainer';
import { RouterProvider } from 'react-router-dom';

function Booking(props) {

  const [paymentApproved, setPaymentApproved] = useState(false);
  const [isRegistred, setIsRegistred] = useState(false);
  const [guestID, setGuestID] = useState();
  const [imageUpload, setImageUpload] = useState(null);

  const [newBalance, setNewBalance] = useState(props.balance);

  //HANDLES CLICK OUT OF DIV
  const ref = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        props.closePopUp();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

    // FORMATING DATE INTO DATE-TEXT
    function formatDate(date) {
      if (date == ""){
          return ""
      } else {
          const options = { year: 'numeric', month: 'long', day: 'numeric' }
          return new Date(date).toLocaleDateString(undefined, options);
      }
    }

    function limitCharacters(text, length){
        let maxLength = length;
        if(text.length > maxLength){
          return text.slice(0, maxLength) + '...'
        } else {
          return text
        }
    }

    function isStringValidDate(dateString) {
      const date = new Date(dateString);
      return !isNaN(date) && date.toString() !== false;
    } 

    useEffect(()=>{
      setNewBalance(props.balance + props.extraFee)
    },[props.extraFee])


    useEffect(()=>{
      console.log(props.balances);
    },[props.balances])



  return (
    <div ref={ref}>
      <div className='wrapper-confirmation'>
        <button className='btnBack' onClick={()=>props.closePopUp()}> <i className="bi bi-chevron-left"></i> Back </button>
        <div className='reservation-details'>
          <div>
            <h1> {props.propertyName} </h1>
            <h2> {props.propertyTitle} </h2>
            <div id="details">
            <h3> Check In</h3>
            <div className='detail'>
              <i className="icon-confirmation bi bi-calendar4-week"></i>  
              <p> {formatDate(props.checkIn)} -  {props.checkTimes[0]} </p>
            </div>
            <h3> Check Out</h3>
            <div className='detail'>
              <i className="icon-confirmation bi bi-calendar4-week"></i>
              <p> {formatDate(props.checkOut)} - {props.checkTimes[1]} </p>
            </div>
            <h3> Full Name</h3>
            <div className='detail'>
              <i className="icon-confirmation bi bi-person"></i>
              <p id="fullName"> {limitCharacters(props.name + " " + props.lastName, 15)}</p>
            </div>
            <h3> Phone</h3>
            <div className='detail'>
              <i className="icon-confirmation bi bi-telephone"></i>
              <p> {props.phone} </p>
            </div>
            <h3> Email</h3>
            <div className='detail'>
              <i className="icon-confirmation bi bi-envelope"></i>
              <p> {props.email} </p>
            </div> 
            <h3>Submit ID</h3>
            <div className='detail'>
              <i className="icon-confirmation bi bi-person-vcard"></i>   
              <input className="form-inputs lowercased" type={"file"} onChange={(e)=>setImageUpload(e.target.files[0])}/>  
            </div>
          </div>

            <h3> Summary </h3>
            <div className='summary'>
              {props.balances.map(balances => (
                <div key={balances.date} className='summary-row'>
                  <p>{isStringValidDate(balances.date) ? formatDate(balances.date) : balances.date}</p>
                  <p>${balances.price.toFixed(2)} </p>
                </div>
              ))}
              <div style={{display : props.extraFee > 0 ? "grid":"none"}} className='summary-row'>
                  <p> Extra Guests Fee</p>
                  <p>${props.extraFee} </p>
              </div>

              <div className='summary-row'>
                <p><b> Total: </b></p>
                <p> <b> ${newBalance.toFixed(2)}</b></p>
              </div>

            </div>
            <div style={{display: imageUpload ? "block":"none"}}>
              <StripeContainer 
                customer={"CheckIn: "+ props.checkIn + " | CheckOut: "+ props.checkOut + " | Name: " + props.name + " " + props.lastName + " | Phone: " + props.phone + " | Email: " + props.email} 
                propertyName={props.propertyName}
                propertyPicture={props.propertyPicture}
                propertyAddress={props.propertyAddress}
                balance={newBalance}
                balances={props.balances}
                name={props.name}
                lastName={props.lastName}
                email={props.email}
                phone={props.phone}
                checkIn={props.checkIn}
                checkOut={props.checkOut}
                propertyID={props.propertyID}
                selectedID={props.selectedID}
                checkTimes={props.checkTimes}
                imageUpload={imageUpload}
                guests={props.guests}
                bedrooms={props.bedrooms}
                bathrooms={props.bathrooms}
              />
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Booking
