import './App.css';
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useActionData } from "react-router-dom";
import ReactGA from "react-ga4";


import Home from "./Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Rentals from './Rentals';
import Property from './Property';
import Services from './Services';
import Login from './Login';
import Contact from './Contact'
import Support from './Support';
import Terms from './Terms';
import Chat from './Chat';
import Reservation from './Reservation';
import PrintableReservation from './components/PrintableReservation';

import { PropertiesProvider } from './context/Properties';


function App() {
  return (
    <PropertiesProvider>
    <div>
      <Header />
      <Router>
        <div className="App">
          <div className="content">
            <Routes>
              <Route path="/" element={<Home />} />
            </Routes>
            <Routes>
              <Route path="/property" element={<Property />} />
            </Routes>
            <Routes>
              <Route path="/services" element={<Services />} />
            </Routes>
             <Routes>
              <Route path="/rentals" element={<Rentals />} />
            </Routes>
            <Routes>
              <Route path="/contact" element={<Contact />} />
            </Routes>
            <Routes>
              <Route path="/login" element={<Login />} />
            </Routes>
            <Routes>
              <Route path="/support" element={<Support />} />
            </Routes>
            <Routes>
              <Route path="/terms-and-conditions" element={<Terms />} />
            </Routes>
            <Routes>
              <Route path="/chat" element={<Chat />} />
            </Routes>
            <Routes>
              <Route path="/reservation" element={<Reservation />} />
            </Routes>
            <Routes>
              <Route path="/printable-reservation" element={<PrintableReservation />} />
            </Routes>
          </div>
        </div>
      </Router>
      <Footer />
    </div>
    </PropertiesProvider>
  );
}

export default App;
