import React, { useDebugValue, useEffect, useState } from "react";
import { FaDoorOpen, FaToilet ,FaUsers} from "react-icons/fa";
import Calendar from "react-calendar";
import Form from "./components/Form.js";
import Map from "./components/Map";
import Loader from "./components/Loader";
import { getFirestore, collection, query, getDocs, setDoc } from 'firebase/firestore';
import { app } from './components/Firebase';
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";


function Property(props) {
  const apiURL = process.env.REACT_APP_APIURL;
  const mailerURL = process.env.REACT_APP_MAILERURL;
  const stripeURL = process.env.REACT_APP_STRIPEURL

  const db = getFirestore(app);
  const [selectedID, setSelectedID] = useState(null);
  const [property, setProperty] = useState();
  const [propertyID, setPropertyID] = useState();
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [searchDate, setSearchDate] = useState();
  const [imgToShow, setImgToShow] = useState(0);
  const [amenities, setAmenities] = useState(null);
  const [pictures, setPictures] = useState(null);
  const [calendar, setcalendar] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [bedrooms, setBedrooms] = useState(0)
  const [bathrooms, setBathrooms] = useState(0)

  const [propertyName, setPropertyName] = useState("")
  const [name, setName] = useState("")
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [scroll, setScroll] = useState(true);
  const [balance, setBalance] = useState(0);
  const [balances, setBalances] = useState([]);

  const [prices, setPrices] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [showBtn, setShowBtn] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false)

  const [checkTimes, setChecksTimes] = useState()
  const [minDatesCheck, setMinDatesCheck] = useState(false);
  const [cleaningFee, setCleaningFee] = useState(0);
  
  const [numOfGuests, setNumOfGuests] = useState(0)
  const [guestsIncluded, setGuestsIncluded] = useState(0);
  const [extraGuestFee, setExtraGuestFee] = useState(0)  
  const [starDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const [rules, setRules] = useState([])
  const [rulesApproval, setRulesApproval] = useState(false)
  const [minNights, setMinNights] = useState(0)
  const [maxNights, setMaxNights] = useState(0)
  const [taxes, setTaxes] = useState([]);


  // useEffect(()=>{
  //   console.log(property);
  // },[property])

  
  
  useEffect(()=>{
    const searchParams = new URLSearchParams(window.location.search);
    setSelectedID(searchParams.get("id"));  
    setShowConfirmation(searchParams.get("confirmed"));
    if(selectedID == null){
      // console.log("Reading ID ...");
    } else {
      fetchData(selectedID)
    } 
  },[selectedID])

  //FUNCTION TO FORMAT DATE YYYY-MM-DD
  const formatDate = (range) => {
    const year = range.getFullYear();
    const month = String(range.getMonth() + 1).padStart(2, '0');
    const day = String(range.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  };

  function taxesTitle(str) {
    let words = str.toLowerCase().split('_');
    let titleCaseStr = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    return titleCaseStr;
  }
    
  function getTaxes(id){
    try {
      fetch(apiURL + '/api/getTaxes',{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          propertyID: id,
        })
      })
      .then(response => response.json())
      .then(response => {       
        for(let i = 0; i < response.length; i ++){
          taxes.push({type: taxesTitle(response[i].type), amount: response[i].amount}) 
        }
    })
    } catch (error){
      console.log(error);
    }
  }

  useEffect(()=>{
    if(propertyID){
     getTaxes(propertyID)
    }
  },[propertyID])

  // FETCHING DATA FROM API
  let selectedProperty;
  async function fetchData(ID) {
    try {
      await fetch(apiURL + '/api/getProperties')
      .then(response => response.json())
      .then(response => selectedProperty = response);
    } catch (error) {
      console.error(error);
    }
    let property = selectedProperty.results[ID]
    // console.log(property); 
    setProperty(selectedProperty.results[ID])
    setPictures(property.pictures);
    setAmenities(property.amenities);
    setPropertyID(property._id);
    fetchCalendar(property._id);
    setCleaningFee(property.prices.cleaningFee);
    setGuestsIncluded(property.prices.guestsIncludedInRegularFee)
    setExtraGuestFee(property.prices.extraPersonFee)
    getMinAndMax(property.nickname)
    setBedrooms(property.bedrooms)
    setBathrooms(property.bathrooms)
    
    let times = [property.defaultCheckInTime, property.defaultCheckOutTime]
    setChecksTimes(times)
  }
  
  // CALLING THE API TO FETCH THE DATES 
  async function fetchCalendar(id) {
    try {
      fetch(apiURL + '/api/getCalendar',{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          propertyID: id,
        })
      })
      .then(response => response.json())

      .then(response => setcalendar(response.data.days))
      setShowCalendar(true)
    } catch (error) {
      console.error(error);
    } 
  }
  
  // STORING -> MAPPING AMENITIES
  let amenitiesMap = [];
  if(!amenities){
    return ;
  } else {  
    for (let [key, value] of Object.entries(amenities)) {
      if(value == "Free parking on premises"){ value = "Free parking"}
      if(value == "Luggage dropoff allowed"){ value = "Luggage dropoff"}
      amenitiesMap.push(
        <div className="amenities" key={key}> 
          <i className="bi bi-caret-right-fill amenitiesIcon"></i> 
          <p>{value}</p>
        </div>
      );
    }
  }

  // STORING -> MAPPING PICTURES
  let picturesMap = [];
  if(!pictures){
    return ;
  } else {
    for (const [key, value] of Object.entries(pictures)) {
      picturesMap.push(<img  key={key} onMouseOver={() => setImgToShow(key)} src={pictures[key].original}/>);
    }
  }

  //STORING -> UNAVAILABLE DATES
  let disabledDates = [];
  if(!calendar){
    return <Loader/>
  } else {
    // for (const [key, value] of Object.entries(calendar)) {
    //   if(calendar[key].status == "unavailable" || calendar[key].status == "booked"){
    //     let unavailbleDay = calendar[key].date
    //     let date = unavailbleDay.split("-");
    //     disabledDates.push(new Date(date[0], parseInt(date[1]) - 1, date[2]));
    //   }
    // }
    let previousStatus = "available"; // Initialize previousStatus as "available"

for (const [key, value] of Object.entries(calendar)) {
  const currentStatus = calendar[key].status;
  
  if (currentStatus === "unavailable" || currentStatus === "booked") {
    let unavailableDay = calendar[key].date;
    let date = unavailableDay.split("-");
    disabledDates.push(new Date(date[0], parseInt(date[1]) - 1, date[2]));
    
    // Check if the previous status was "available" and remove the current date from disabledDates
    if (previousStatus === "available") {
      disabledDates.pop();
    }
  }

  // Update the previousStatus for the next iteration
  previousStatus = currentStatus;
}

  }

  //  WHEN DATES ARE SELECTED
  const onDateSelected = async (range) => {
    setBalance(0);
    setSelectedDates([]);
    setCheckIn(formatDate(range[0]));
    setCheckOut(formatDate(range[1]));
    getBalance(formatDate(range[0]), formatDate(range[1]))
    rules ? checkRules(range[0], range[1]) : setRulesApproval(true)
  }

  //GETTING MIN AND MAX NIGHTS PER PROPERTY - FIREBASE
  async function getMinAndMax(propertyName){
    const docRef = doc(db, "Properties", propertyName);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setMinNights( docSnap.data().min)
        setMaxNights( docSnap.data().max)
        setRules(docSnap.data().rules)
      }
  }  


  //DISABLING UNAVAILABLE DATES FROM THE CALENDAR
  function isDateDisabled(date) {
    return disabledDates.some(disabledDate => 
      disabledDate.getDate() === date.getDate() &&
      disabledDate.getMonth() === date.getMonth() &&
      disabledDate.getFullYear() === date.getFullYear()
    );
  }

  // GETTING FEES AND FINAL BALANCE
  function getBalance(start, end){
    let totBalance = 0;
    let accommodationFare = 0;
    balances.length = 0;    
    let currentDate = new Date(start);
    let endDate = new Date(end);

    //GETTING DATES BETWEEN START AND END
    const dates = [];
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate).toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'}));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    for (let i = 0; i < dates.length -1; i++){
      selectedDates.push(dates[i]);
    }
    
    checkMinAndMaxNights(selectedDates)
  
    // ADDING ALL SELECTED DATES WITH IT'S PRICE
    for(let i = 0; i < calendar.length; i ++){
      if(selectedDates.includes(calendar[i].date)){
        balances.push({ date: calendar[i + 1].date, price: calendar[i].price});
        accommodationFare = accommodationFare + calendar[i].price;
      }
    }
    // ADDING TAXES 
    for(let i =0; i < taxes.length; i ++){
      let sum =  cleaningFee + accommodationFare + (accommodationFare*0.037);
      balances.push({date:taxes[i].type, price: (sum*taxes[i].amount)/100})
    }
    // ADDING EXTRA FEES 
    balances.push({date: "Cleaning Fee", price: cleaningFee})
    balances.push({date: "Service Fee", price: accommodationFare*0.037})
    // CALCULATING TOTAL BALANCE
    for(let i = 0 ; i < balances.length; i ++){
      totBalance = totBalance + balances[i].price;
    }  
    setBalance(totBalance);
   setBalances(balances)
  }


  function checkMinAndMaxNights(selectedDates){
    if(selectedDates.length >= minNights && selectedDates.length <= maxNights){
      setShowBtn(true);
      setMinDatesCheck(true)
    } else if (selectedDates.length <= minNights) {
      alert("You have to select at least " +  minNights + " nights");
      setShowBtn(false)
      setMinDatesCheck(false)
    } else if ( selectedDates.length >= maxNights) {
      alert("You have to select less than " +  maxNights + " nights");
      setShowBtn(false)
      setMinDatesCheck(false)
    }
  }


  const formatDate2 = (range) => {
    const year = range.getFullYear();
    const month = String(range.getMonth() + 1).padStart(2, '0');
    const day = String(range.getDate()).padStart(2, '0');
    return `${month}/${day}/${year}`;
  };

  function checkRules(start, end){
    let currentDate = new Date(start);
    let endDate = new Date(end);
    
    //GETTING DATES BETWEEN START AND END
    let dates = [];
    while (currentDate <= endDate) {
      dates.push(formatDate2(new Date(currentDate)));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    
    let minNights = 0;

    for(let i = 0; i < dates.length; i ++){
      for(let j = 0; j < rules.length; j ++){
        if(rules[j].split(' - ')[0] == dates[i]){
          if(parseInt(rules[j].split(' - ')[1]) > minNights){
            minNights = parseInt(rules[j].split(' - ')[1])
          }
        }
      }
    }

    if(dates.length - 1 < minNights){
      alert("You have to select at least " + minNights + " nights")
    } else {
      setRulesApproval(true)
    }
  }







  return (
    <div style={{overflowY:"hidden"}}>
      <div className="confirmationScreen" style={{display: showConfirmation ? "flex":"none"}}>
        <i className="bi bi-calendar2-check calendarCheckIcon"></i>
        <p><b> Thanks for booking with us ! </b></p>
        <p> You will soon receive an email containing your booking information.</p>
        <a href="/"> Go to home page  </a>
      </div>
      {property ? (
        <div className="wrapper-booking" >
          <div id="left">
            <div className="pictures">
                <img id="mainPicture" src={property.pictures[imgToShow].original} />
                <div className="galery">{picturesMap}</div>
            </div>

            <h3 className="nickname"> {property.title}</h3>
            <p className="address"> <i className="bi bi-geo-alt"></i> {property.address.city}, {property.address.state}</p>
            <p>{property.publicDescription?.summary || ''}</p>

            <div className="capacities">
              <p>
                <FaUsers className="icon" /> {property.accommodates} people{" "}
              </p>
              <p>               
                <FaDoorOpen className="icon" /> {property.bedrooms} bedrooms{" "}
              </p>
              <p>
                <FaToilet className="icon" /> {property.bathrooms} bathrooms{" "}
              </p>
            </div>
            <Map title={property.nickname} lat={property.address.lat} long={property.address.lng}/>
            <h2> Amenities Included: </h2>
            <div className="allAmenities">{amenitiesMap}</div>
          </div>
          <div id="right">
            <h3> Book Now </h3>
            <div className="calendar-loader" style={{display: showCalendar? "none" : "block"}}>
              <p> Loading ... </p>
            </div>
            <div style={{display: showCalendar? "block" : "none"}}>
              <Calendar
                   onActiveStartDateChange={(e) => setSearchDate(e.activeStartDate)}
                   className="calendar"
                   view="month"
                   minDate={new Date()}
                   selectRange={true}
                   onChange={onDateSelected}
                   tileDisabled={({date, view}) => view === 'month' && isDateDisabled(date)}
                   tileContent={({ date }) => {
                  const currentDate = date.toISOString().split('T')[0];
                  const previousDate = new Date(date);
                  previousDate.setDate(previousDate.getDate() );
                  const previousDateISO = previousDate.toISOString().split('T')[0];
              
                  const currentUnavailableDate = calendar.find(
                    item => item.date === currentDate
                  );
                  const previousUnavailableDate = calendar.find(
                    item => item.date === previousDateISO
                  );
                  if (previousDateISO === calendar[0].date || currentDate === calendar[0].date) {
                    return null; // Skip displaying price for the first day
                  }
                  return (
                    <div>
                      {previousUnavailableDate && (
                        <span className="prices">${previousUnavailableDate.price}</span>
                      )}
                    </div>
                  );
                   }}
              ></Calendar>
             </div>
            <Form 
              checkIn={checkIn} 
              checkOut={checkOut} 
              minDatesCheck={minDatesCheck}
              selectedDates={selectedDates}
              balance={balance}
              balances={balances}
              propertyID={property._id}
              propertyName={property.nickname} 
              propertyTitle={property.title}
              propertyPicture={property.pictures[0].original} 
              propertyAddress={property.address.full}
              showPopUp={()=>setScroll(false)}
              showBtn={showBtn}
              selectedID={selectedID}
              checkTimes={checkTimes}
              extraGuestFee={extraGuestFee}
              guestsIncluded={guestsIncluded}
              taxes={taxes}
              bedrooms={bedrooms}
              bathrooms={bathrooms}
              rulesApproval={rulesApproval}
            />
          </div> 
        </div>
      ) : (
        <div> Loading Data ... </div>
      )}
    </div>
  );
}
export default Property;
