import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import {getAuth, GoogleAuthProvider} from "firebase/auth"


 const firebaseConfig = {
  apiKey: "AIzaSyCFC0dwnbeqz_vCLUIv6yg3piDwFXwAvm0",
  authDomain: "betterstays-71a09.firebaseapp.com",
  projectId: "betterstays-71a09",
  storageBucket: "betterstays-71a09.appspot.com",
  messagingSenderId: "1055920424862",
  appId: "1:1055920424862:web:ced65ad7686ade7775388f"
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const storage = getStorage(app)
export const db = getFirestore(app);
export const auth = getAuth(app)
export const provider = new GoogleAuthProvider();