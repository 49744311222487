import React, { useEffect, useLocation } from 'react'
import { useState } from 'react'
  // import { useSVGOverlay } from 'react-leaflet/lib/SVGOverlay'
import { Link } from 'react-router-dom'
import { auth, provider } from '../components/Firebase';
import {signInWithPopup} from "firebase/auth"

function Header() {

  const [selected, setSelected] =  useState(0);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  
  useEffect(()=>{
    let currentURL = window.location.href;
     if(currentURL.includes('rentals')){setSelected(1)}
     else if (currentURL.includes('property')){setSelected(1)}
     else if (currentURL.includes('services')){setSelected(2)}
     else if (currentURL.includes('contact')){setSelected(3)}
     else if (currentURL.includes('login')){setSelected(4)}
     else if (currentURL.includes('support')){setSelected(3)}
     else { setSelected(0) }
  },[])

  function btnClicked(){
    setShowMobileMenu(!showMobileMenu)
  }



  return (
    <div className='wrapper-header' id="wrapper-header">
      <a href='/'>  <img className="logo-header"src="https://res.cloudinary.com/dxfi1vj6q/image/upload/v1682964660/Final_Logo_1_byrdtx.png" /> </a>
      <div className='mobileMenu' id={showMobileMenu? "showing" : "hidding"}>
         <a href='/'>         <button className={selected == 0 ? "btnSelected" : "notSelected"}> Home     </button> </a>
         <a href='/rentals'>  <button className={selected == 1 ? "btnSelected" : "notSelected"}> Rentals  </button> </a>
         <a href='/services'> <button className={selected == 2 ? "btnSelected" : "notSelected"}> Services </button> </a>
         <a href='/contact'>  <button className={selected == 3 ? "btnSelected" : "notSelected"}> Contact  </button> </a>
         <a href='/login'>    <button className={selected == 4 ? "btnSelected" : "notSelected"}> Login    </button> </a>
      </div>
      <div className='mobile-header'>
      <a href='/'>  <img src="https://res.cloudinary.com/dxfi1vj6q/image/upload/v1682964660/Final_Logo_1_byrdtx.png" /> </a>
        <button className="btnMobileMenu" onClick={()=> btnClicked()} style={{display : showMobileMenu ? "none" : "block"}}> <i className="bi bi-list"></i> </button>
        <button className="btnMobileMenu closeMenuBtn" onClick={()=> btnClicked()} style={{display : showMobileMenu ? "block" : "none"}}> <i className="bi bi-x-lg"></i> </button>
      </div>
      <div className='menu'>
         <a href='/'>         <button className={selected == 0 ? "btnSelected" : "notSelected"}> Home     </button> </a>
         <a href='/rentals'>  <button className={selected == 1 ? "btnSelected" : "notSelected"}> Rentals  </button> </a>
         <a href='/services'> <button className={selected == 2 ? "btnSelected" : "notSelected"}> Services </button> </a>
         <a href='/contact'>  <button className={selected == 3 ? "btnSelected" : "notSelected"}> Contact  </button> </a>
         <a href='/login'>    <button className={selected == 4 ? "btnSelected" : "notSelected"}> Login    </button> </a> 
       
      </div>
    </div>
  )
}
export default Header
