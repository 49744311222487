import React, { useState, useEffect, useRef } from 'react'
import { getFirestore, collection, query, getDocs, setDoc } from 'firebase/firestore';
import { app } from './components/Firebase';
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import adminTail from './images/adminTail.png'
import customerTail from './images/customerTail.png'


import useSound from 'use-sound'
import mySound from './components/notification.mp3'
import { FaCreativeCommonsNcEu, FaCreativeCommonsSamplingPlus } from 'react-icons/fa';

function Chat() {

    const db = getFirestore(app);
    const messagesContainerRef = useRef(null);


    const [messages, setMessages] = useState([])
    const [contacts, setContacts] = useState([])

    const [strNewMesasge, setStrNewMesasge] = useState('')
    // const [conversation,setConversation] = useState('espinosa9mx@gmail.com - Diego Espinosa')

    const [receivedMessages, setReceivedMessages] = useState(0);
    const [firstCount, setFirstCount] = useState(true)
    const [playSound] = useSound(mySound)
    const [showHeader, setShowHeader] = useState(true)
    const [customerURL, setCustomerURL] = useState('')
    const [name, setName] = useState('')
    const [count, setCount] = useState(0)
    let temp = ''
   

    useEffect(()=>{
      document.getElementById('wrapper-footer').style.display = 'none';

      const searchParams = new URLSearchParams(window.location.search);
      if(searchParams.get("header") == 'false'){
        setShowHeader(false)
        document.getElementById('wrapper-header').style.display = 'none';
      }
      temp = searchParams.get("customer")
    },[])


    function chatURL(){
      const searchParams = new URLSearchParams(window.location.search);
      return searchParams.get("customer")
    }
   
    useEffect(()=>{
        console.log("Open Conversation: " + temp);
        const messagesRef = doc(db, "Messages", temp);
        onSnapshot(messagesRef, (doc) => {
            const data = doc.data();
            if (data) {
                const keys = Object.keys(data);
                keys.sort();
                const sortedData = {};
                keys.forEach((key) => {
                    sortedData[key] = data[key];
                });
                setMessages(sortedData)              
            }
        });        
        setName(temp.split( ' - ')[1])
    },[customerURL])

    useEffect(() => {
      let received = 0
      for( let i = 0; i < Object.keys(messages).length; i ++ ){
        if(Object.keys(messages)[i].includes('admin')){
          received ++
        }
      }
      compareCount(received)
    }, [messages]);

    function compareCount(num){
      if(num > receivedMessages){
        if(firstCount == false){
          playSound()
        }
      } 
      setFirstCount(false)
      setReceivedMessages(num)
    }
    

    async function sendMessage() {
      try {
        console.log("Sending Message ..." + chatURL());
        const newMessageRef = doc(db, "Messages", chatURL());
        const updateData = {};
        updateData[generateKey()] = strNewMesasge;
        await updateDoc(newMessageRef, updateData);
        sendNotification()
        setStrNewMesasge('')
        updateLastMessage()
      } catch {
        console.log("Creating new conversation");
        await setDoc(doc(db, "Messages", chatURL()), {
        });
        sendMessage()
      }
    }

    async function sendNotification(){
      console.log("Sending Notification ...");
       var myHeaders = new Headers();
       myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
       
       var urlencoded = new URLSearchParams();
       urlencoded.append("name", name);
       urlencoded.append("message", strNewMesasge);
      
       var requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: urlencoded,
         redirect: 'follow'
       };
       
       await fetch("https://better-stays-mailer.vercel.app/api/chatNotification", requestOptions)
         .then(response => response.text())
         .then(result => console.log("Email Sent: " + result))
         .catch(error => console.log('== ERROR === ', error));

    }

    function generateKey(){
        const currentDate = new Date();
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
        const dateTimeString = currentDate.toLocaleString('en-US', options);
        return (dateTimeString + ' customer');
    }

    function getTime(str){
        const timeRegex = /\d{1,2}:\d{2}:\d{2}\s[APap][Mm]/;
        const extractedTime = str.match(timeRegex)[0];
        const timeParts = extractedTime.split(":");
        const hour = parseInt(timeParts[0]);
        const minute = parseInt(timeParts[1]);
        const period = timeParts[2].split(" ")[1];
        const formattedTime = `${hour}:${minute.toString().padStart(2, '0')} ${period}`;
        return(formattedTime);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault();
          sendMessage()
        }
    };

    useEffect(() => {
        if (messagesContainerRef.current) {
          messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
      }, [messages]);

      async function updateLastMessage(){
        await setDoc(doc(db, "LastMessages", chatURL() ), {
          message: strNewMesasge,
          status: 'sent',
          date : generateKey(), 
          user: chatURL()
        });
      }


  return (
    <div className='wrapper-chat'>
        <div  className={showHeader? 'messages':'noHeader'} ref={messagesContainerRef} >
          <div className='topNav' style={{display: showHeader? 'none':'flex'}}>
            <i className="bi bi-person-gear"></i> Support Team 
          </div>
            {Object.keys(messages).map((key) => (
                <div key={key}>
                  <div className={key.includes('admin') ? 'buble customer-buble' : 'buble admin-buble'}>
                    <p> {messages[key]} </p>
                  </div>
                  <img className="adminTail" style={{display:key.includes('admin') ? "none":"block" }} src={adminTail} />
                  <img className="customerTail" style={{display:key.includes('customer') ? "none":"block" }} src={customerTail} />
            
                  <p className={key.includes('admin') ? 'message-hour admin-hour' : 'message-hour customer-hour'} >
                    {getTime(key)}
                  </p>
                </div>
            )
            )
            }
        </div>
        <div className='new-message'>
            <textarea placeholder='Type your message' rows={4} value={strNewMesasge} onKeyDown={handleKeyDown} onChange={(e)=>setStrNewMesasge(e.target.value)} />
            <i className="bi bi-arrow-up-circle-fill arrowIcon" onClick={()=>sendMessage()}></i>
        </div>
    </div>
  )
}

export default Chat
