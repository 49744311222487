import React, { useDebugValue, useEffect, useState, useRef } from "react";
import Map from "./components/Map";
import Loader from './components/Loader'


function Reservation() {
  const iframeRef = useRef(null);
  
  const apiURL = process.env.REACT_APP_APIURL;
  const mailerURL = process.env.REACT_APP_MAILERURL;
  const stripeURL = process.env.REACT_APP_STRIPEURL


    const [reservationID, setReservationID] = useState()
    const [reservation, setReservation] = useState([])
    const [property, setProperty] = useState([])
    const [coverImg, setCoverImg] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [email, setEmail] = useState('')
    const [showChat, setShowChat] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const [chatUrl, setChatUrl] = useState('')
    const [printableReservationURL, setPrintableReservationURL] = useState('')

    const searchParams = new URLSearchParams(window.location.search);
    useEffect(()=>{
        if(window.innerWidth < 800){
            setIsMobile(true)
          }
    },[])
    useEffect(()=>{
        setReservationID(searchParams.get("id"));  
        setEmail(searchParams.get("email"));  
        if(reservationID == null){
          // console.log("Reading ID ...");
        } else {
            getReservation(reservationID)
        } 
    },[reservationID])

    useEffect(()=>{
        if(!isLoading){
           setChatUrl(searchParams.get("email") + ' - ' + reservation.guest.fullName + ' - ' + property.nickname)
        }
    },[isLoading])

    async function getReservation(){
        await fetch(apiURL + '/api/getReservations')
        .then(response => response.json())
        .then(response => {
            let reservations = response.results
            for (let i =0 ; i < reservations.length; i ++){
                if(reservations[i]._id == reservationID){
                    setReservation(reservations[i])
                    getProperty(reservations[i].listingId)
                }
            }
        })
    }

    async function getProperty(propertyID){
        await fetch(apiURL + '/api/getProperties')
        .then(response => response.json())
        .then(response => {
            let properties = response.results;
            for(let i = 0; i < properties.length; i ++){
                if(properties[i]._id == propertyID){
                    setProperty(properties[i])
                    setCoverImg(properties[i].pictures[0].original)
                }
            }  
            setIsLoading(false)
        })
    }

    function renderAmenities(array){
      return Object.keys(array).map((key) => (
        <p key={key} className='amenitie'>
          {array[key]}
        </p>
      ))
    }

    function formatDate(value){
      const date = new Date(value);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-US', options);
      return formattedDate;
    }


    if (isLoading) {
        return (
          <div className="loading-reservation">
              <Loader type="reservations" />
          </div>
        ); 
    }



    
    function printReservation(){
        if(printableReservationURL =='/printable-reservation?id=' + reservationID ){
          iframeRef.current.contentWindow.location.reload();
        } else {
          setPrintableReservationURL('/printable-reservation?id=' + reservationID)
        }
    }

  return (

    <div className='wrapper-reservation'>
      <div className="content">    

        <img  className="coverImg" src={coverImg} />
        <h1> {property.nickname} </h1>
        <h2> {property.title} </h2>
        <p id="greet"> Hello, {reservation.guest.fullName} 👋</p>

        <div className='two-col firstRow'>
            <div>
              <i className="bi bi-geo-alt iconAddress"> Address: </i> 
              <p id="address"> {property.address.full}</p>
              <p id="address"><a href={'https://google.com/maps/place/' + property.address.street} target="_blank"> Open in Google Maps </a></p>
            </div>
            <div className='three-col rooms'>
              <div className='room'> 
              <img src='https://res.cloudinary.com/dxfi1vj6q/image/upload/v1692845362/20C3FA4C-5CF6-4080-9847-74EEF17B4A7B_dpk4vk.png'/>
                {property.bedrooms} Bedrooms 
              </div>
              <div className='room'> 
              <img src='https://res.cloudinary.com/dxfi1vj6q/image/upload/v1692844876/223CBEBB-3F2E-47BF-9A91-A605B5710A43_qvquu5.png'/>
                {property.bathrooms} Bathrooms 
              </div>
              <div className='room'> 
                <img src='https://res.cloudinary.com/dxfi1vj6q/image/upload/v1692844876/76F918DA-FDBD-4547-9D12-C63021E941F7_v2za3i.png'/>
                {property.beds} Beds 
              </div>
            </div>
        </div>
        <div className='dates'>
            <div>
              <i className="bi bi-calendar2-week iconCalendar"> Check In : </i>
              <p> {formatDate(reservation.checkIn)} - {property.defaultCheckInTime} </p>
            </div>
            <div></div>
            <div>
              <i className="bi bi-calendar2-week iconCalendar"> Check Out : </i>
              <p> {formatDate(reservation.checkOut)} - {property.defaultCheckOutTime} </p>
            </div>
        </div>

        <Map showLocation={true} title={property.nickname} lat={property.address.lat} long={property.address.lng}/>


        <i class="bi bi-house-check iconAmenities"> Amenities: </i>
        <div className='amenities'>
          {renderAmenities(property.amenities)}
        </div>        
      </div>
      <button onClick={isMobile ? ()=>window.open('/chat?customer='+chatUrl, '_blank') : ()=>setShowChat(!showChat)} className='btnChat'> <i className={showChat ? "bi bi-chevron-down":"bi bi-chat-right-text"}></i> </button>
      <iframe style={{display: showChat? "block":"none"}} className='chat-popup' src={'/chat?header=false&&customer=' + chatUrl} />

      <button onClick={()=>printReservation()} className='btnPrint'> <i className="bi bi-printer"></i> </button>
      <iframe src={printableReservationURL} ref={iframeRef} />

    </div>
  )
}

export default Reservation
